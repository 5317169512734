import React from 'react'
import Layout from '../components/Layout'

const ContactPage = () => (
    <Layout>
        <div className="Contact">
            <span>Einfach eine Mail an:</span>
            <a className="Contact__link" href="mailto:office@kasparachenbach.com">
                office@kasparachenbach.com
            </a>
        </div>
    </Layout>
)

export default ContactPage
